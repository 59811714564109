import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import { Container } from "../components/global";

import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer";

import { Content, Section, P, H2 } from "../styles/TCandPrivacyStyles";

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Service Catalog" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <Section>
          <H2>
            <span>OPay Business provides:</span>
          </H2>
          <P>1. Online & offline digital payment servicesn</P>
          <P>2. POS devices application & maintainence</P>
          <P>3. Store & employee management</P>
          <P>4. Product sales & inventory management</P>
          <P>5. Business reports, settlement & reconciliation</P>
        </Section>

        <Section>
          <H2>
            <span>OPay Personal App Guarantees</span>
          </H2>
          <P>
            <span>1. Deposit, Withdraw, Transfer</span>
          </P>
          <P>
            Provide users with deposit, withdrawal, and transfer services based on three means-- OPay App, card and
            USSD.
          </P>
          <P>
            <span>2. Utility Bill Payment Service</span>
          </P>
          <P>
            Provide users with online utility bill payment services anytime and anywhere (including phone bills, data
            bills, gaming, electricity bills, TV bills, etc.).
          </P>
          <P>
            <span>3. Opay Card</span>
          </P>
          <P>Provide users with easy bank card approval services.</P>
          <P>
            <span>4. Financing</span>
          </P>
          <P>Provide users with inclusive and flexible financial products (OWealth, Fixed, Targets, etc.).</P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);
export default TermsAndConditions;

const pageData = {
  headerData: {
    captionText: "Service Catalog",
  },
};
